<!--
 * @Descripttion: 个人信息页面组件
 * @Author: Banbri
 * @Date: 2021-07-11 14:59:00
 * @LastEditors: Banbri
 * @LastEditTime: 2021-06-08 15:56:58
--> 

<template>
  <div class="user-details" id="user-details" name="user-details">
    <div class="user-details-layout">
      <el-row :gutter="10">
        <div>
          <CenterMenu></CenterMenu>
        </div>
        <el-col :span="20">
          <div class="user-details-content">
            <div class="extra"></div>
            <div class="user-details-title">
              <p>个人信息</p>
            </div>
            <div class="user-details-form">
              <el-form ref="form" :model="form" status-icon :rules="rules" label-width="80px">
                <el-form-item label="头像:">
                  <el-upload
                    class="avatar-uploader"
                    label="描述"
                    ref="upload"
                    :on-success="uploadSuccess"
                    :action="imgPostUrl"
                    :before-upload="fnBeforeUpload"
                    :show-file-list="false"
                  >
                    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    <div class="el-upload__tip" slot="tip">点击上传头像,只能上传png/jpg文件，且不超过2M</div>
                  </el-upload>
                </el-form-item>
                <el-form-item prop="nick_name" label="昵称:   ">
                  <el-input v-model="form.nick_name"></el-input>
                </el-form-item>
                <el-form-item prop="userName" label="用户名:   ">
                  <el-input v-model="form.userName"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" style="margin-bottom:83px" @click="save('form')">保存</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import CenterMenu from '../components/CenterMenu'
import { mapActions } from 'vuex'
export default {
  name: 'Details',
  data() {
    var validateNick = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入昵称'))
      } else if (value.length < 2 || value.length > 10) {
        callback(new Error('昵称长度需在2到10之间'))
      }
      callback()
    }
    var validateUser = (rule, value, callback) => {
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      const userNameRule = /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/;
      if (value === '') {
        callback(new Error('请输入用户名'))
      } else if (!userNameRule.test(value)) {
        callback(new Error('字母开头,长度5-16之间,允许字母数字下划线'))
      }
      callback()
    }
    return {
      imageUrl: '',
      form: {
        user_id: 0,
        nick_name: '',
        avatar: '',
        userName: ''
      },
      imgPostUrl:'api/common/uploadPicture',
      rules: {
        nick_name: [{ validator: validateNick, trigger: 'blur' }],
        userName: [{ validator: validateUser, trigger: 'blur' }]
      }
    }
  },
  methods: {
    ...mapActions(['setUser']),
    fnBeforeUpload(file) {
      const isPNG = file.type === 'image/png' || file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isPNG) {
        this.$message.error('上传头像图片只能是 PNG/JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isPNG && isLt2M
    },
    save(formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return
        }
        this.$axios
          .post("/api/users/updateUser", this.form)
          .then(res => {
            this.notifySucceed(res.msg)
            // 登录信息存到本地
            let user = JSON.stringify(this.form)
            localStorage.setItem('user', user)
            // 登录信息存到vuex
            this.setUser(this.form)
            this.$router.push({
              name: 'Center'
            })
          })
          .catch(err => {
            return Promise.reject(err);
          });
      })
    },
    uploadSuccess(res) {
        this.imageUrl = this.$target+res.data
        this.form.avatar = res.data
    },
  },
  beforeMount() {
    this.form.user_id = this.$store.getters.getUser.user_id
    this.form.userName = this.$store.getters.getUser.userName
    this.form.nick_name = this.$store.getters.getUser.nick_name
    this.form.avatar = this.$store.getters.getUser.avatar
    this.imageUrl = this.$target+this.$store.getters.getUser.avatar
  },
  components: {
    CenterMenu
  }
}
</script>
<style>
.user-details-layout {
  max-width: 1225px;
  margin: 0 auto;
}
.user-details-content {
  background-color: #ffffff;
  margin-bottom: 30px;
}
.user-details-title {
  height: 100px;
  display: flex;
  align-items: center;
}
.user-details-title p {
  font-size: 30px;
  color: #757575;
  margin-left: 50px;
}
.user-details-form {
  width: 500px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #ff6700;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  max-width: 178px;
  max-height: 178px;
  border-radius: 100%;
  display: block;
}
.extra {
  height: 10px;
}
</style>